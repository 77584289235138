import rollbar       from 'rollbar';

class RollbarLogger{
  constructor(){
	  this.isoRollbar = null;

	  let environment = 'dev';
	  if( typeof __ENV__ === 'object'){
		  if(__ENV__.production) environment = 'production';
	  }

	  this.defaultConfig = {
  	  accessToken: __ROLLBAR__,
      enabled: false,
      logLevel: 'warning',
      reportLevel: 'error',
      captureIp: 'anonymize',
      maxItems:20,
      captureUncaught: true,
      captureUnhandledRejections: true,
      autoInstrument: true,
      payload: {
        environment,
        person:{
          id:null,
        },
      },
    };
    this.setupRollbar();

  }

  setupRollbar() {
    this.isoRollbar = new rollbar(this.defaultConfig);
    window.Rollbar = this.isoRollbar;
    
  }
}

const RollbarLog = new RollbarLogger();
export default RollbarLog;
