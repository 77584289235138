import { capitalize, titleize } from 'HELPERS/text';

export default class LokaliseLanguage {
  constructor() {
    this.localLanguage = {};
    
  }

  load = (lang = null) => new Promise(async (resolve, reject) => {

      let url;
      if(__ENV__ == 'local') url = '.';
      if(__ENV__ == 'development') url = `${window.location.origin}${window.location.pathname}`;
      if(__ENV__ == 'production') url = 'https://stp.preferabli.com';
// 
      const localeFetch = await fetch(`${url}/locale/${lang}.json`, { 
          method: 'GET',
          headers: {
            'Accept': 'application/json'
          }
      });

      const _lang = await localeFetch.json();

      if(_lang){
        this.localLanguage = _lang;
        resolve();
      }

  });

  getSlugTranslation = ({ slug, canTitleize = false }) => {
    if(!Object.keys(this.localLanguage).includes(slug)) return false;
    let textSlug = this.localLanguage[slug];
    if(canTitleize) textSlug = titleize(textSlug);
    return textSlug;
  }
  
}