import { underscore } from 'HELPERS/text';
import mixpanelbrowser from 'mixpanel-browser';

export default class Mixpanel{
  constructor(){
    this.mp = mixpanelbrowser.init(__MIXPANEL__, { 
      batch_requests: true,
      ignore_dnt: true,
      track_pageview: true,
      opt_out_tracking_persistence_type: 'cookie',
    }, 'stp');

    this.group = null;

  }

  track(event, params){
     this.mp.track(`${underscore(event)}`, { ...params });
  }

   setIntegrationGrouping(integration_id, params = {}){
     let _group = this.mp.get_group('integration_id', integration_id);

    if(!_group) {
      this.mp.add_group('integration_id', integration_id);
      this.group = this.mp.get_group('integration_id', integration_id);
      this.group.set_once({"integration_id": integration_id, ...params});
    }
    if(_group) {
      this.group = _group;
      this.group.set_once({"integration_id": integration_id, ...params});
    }
  }

  setGroupProperties(params){
     this.group.set(params);
  }

  identify(integration_id){
    this.mp.identify(integration_id);
  }

  optOutTracking(trackBoo){
    this.mp.set_config({
      opt_out_tracking_by_default: trackBoo
    });

    if(trackBoo) this.mp.opt_out_tracking();
  }
}

