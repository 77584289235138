class LocalSession {
	constructor() {
		this.session_prefix = 'pjs_lttt';
		this.storage = {
			cookies: {},
			session: {},
		};
	}

	getLocalCookies = () => {
		var decodedCookie = decodeURIComponent(document.cookie);
		var cookies = decodedCookie.split('; ').filter((cookie) => cookie.startsWith(`${this.session_prefix}`));
		var sessionItems = Object.entries(window.sessionStorage).filter(([key, value]) => key.startsWith(`${this.session_prefix}_`));

		if (sessionItems.length) {
			for (const [key, value] of sessionItems) {
				const isJSON = this.checkValidJson(value);
				this.storage.session[key.toLowerCase().replace(`${this.session_prefix}_`, '')] = isJSON ? JSON.parse(value) : value;
			}
		}

		if (cookies.length) {
			cookies.forEach((cookie) => {
				const [key, value] = cookie.split('=');
				this.storage.cookies[key.toLowerCase()] = value;
			});
		}
	}

	checkValidJson = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	getSessionStorage = () => this.storage.session || null;

	updateSessionData = (sessionKey, value) => {
		window.sessionStorage.setItem(`${this.session_prefix}_${sessionKey.toLowerCase()}`, JSON.stringify(value));
		this.storage.session[sessionKey.toLowerCase()] = value;
	};

	getCookies = () => this.storage.cookies || null;

	clearSessionData = () => {
		window.sessionStorage.clear();
		this.storage.session = {};
	};

	createSessionItems = (params) => {
		if (Object.keys(params).length) {
			Object.keys(params).map((sessionKey) => {
				window.sessionStorage.setItem(`${this.session_prefix}_${sessionKey.toLowerCase()}`, JSON.stringify(params[sessionKey]));
				this.storage.session[sessionKey.toLowerCase()] = params[sessionKey];
			});
		}
	};

	createCookie = (cookies) => {
		if (Array.isArray(cookies) && cookies.length) {
			cookies.map((cookieParams) => {
				const { name, value, expired } = cookieParams;

				document.cookie = `${name}=${value};expires=${expired};domain=.${window.location.host};path=/;`;
				this.storage.cookies[name.toLowerCase()] = value;
			});
		}
	};
}

const Session = new LocalSession();

export default Session;
