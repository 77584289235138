export const arrayIntersection = (array1, array2) => array1.filter(value => array2.includes(value));

export const getArrayMin = (array, key) => (array && array.length
  ? array.reduce((min, obj) => (min <= obj[key] ? min : obj[key]), array[0][key]) : 0);

export const chuckArray = (arr, size = 20, cache = []) => {
  const tmp = [...arr];
  if (size <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, size));
  return cache;
};


export const arrayShuffle = (array) => {
  var ctr = array.length; var temp; var
    index;
  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * ctr);
    // Decrease ctr by 1
    ctr--;
    // And swap the last element with it
    temp = array[ctr];
    array[ctr] = array[index];
    array[index] = temp;
  }
  return array;
};


export const arrayReverse = (array) => {
  for (let i = 0; i < Math.floor(array.length / 2); i++) {
    [array[i], array[array.length - 1 - i]] = [array[array.length - 1 - i], array[i]];
  }
  return array;
}

export const hasMultipleValues = (searchArr, searchKeys) => {
  return searchKeys.every((key) => searchArr.includes(key));
}

export const hasSomeValues = (searchArr, searchKeys) => {
  return searchKeys.some((key) => searchArr.includes(key));
}