import Mixpanel from './Mixpanel';
import { underscore } from 'HELPERS/text';
import Context from 'UTILS/Context';

export default class PreferabliAnalytics{
  constructor(){
    this.product_id = null;
    this.integration_id = null;
    this._mp = null;
  }

  setup({ integration_id, opt_out_tracking }){
    this.integration_id = integration_id;

    if(!opt_out_tracking) {
      this._mp = new Mixpanel();
      this._mp.identify();
      this._mp.setIntegrationGrouping(integration_id);
    }

  }

  setProductId(product_id){
   this.product_id = product_id;
  }

  setIntegrationId(integration_id){
   this.integration_id = integration_id;
  }

  track(event, params){

     const _defaults = {
      lttt_rec_product_id: `${this.product_id}`,
      lttt_rec_product_url: `${window.location.href}`,
      integration_id: `${this.integration_id}`,
      integration_hostname: `${window.location.hostname}`,
     }

     if(this._mp) {
      this._mp.track(`${underscore(event)}`, { ...params, ..._defaults });
      }
  }
  
}