import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';

const MaterialIcon = ({
	icon,
	classNames,
	size,
}) => {
	const [mIcon, setIcon] = useState(icon);

	useEffect(() => {
		setIcon(icon);
	}, [icon]);

	return (
		<i className={ String(`material-icons ${classNames}${size ? `md-ico-${size}` : ''}`).trim() }>{mIcon}</i>
	);
};

MaterialIcon.propTypes = {
	icon: PropTypes.string,
	classNames: PropTypes.string,
	size: PropTypes.string,
};
MaterialIcon.defaultProps = {
	icon: '',
	classNames: '',
	size: '',
};

export default MaterialIcon;
