const locale_language =
  window.navigator.language || window.navigator.userLanguage;

export const localePrice = (price, currency = 'USD', decimals) => {

  if (decimals === 0 && price && price.toString().split('.').length) {
    price = Number(price.toString().split('.')[0]);
  }

  let price_string = new Intl.NumberFormat(locale_language, {
    currency,
    style: 'currency',
    currencyDisplay: /iPad|iPhone|iPod/.test(navigator.userAgent)
      ? 'symbol'
      : 'narrowSymbol',
    minimumFractionDigits: decimals,
  }).format(price);
  price_string = price_string.replace(/\s/g, '');
  return price_string;
};

export const toCurrency = (amount, options = {}) => {
  const config = {
    currency: 'USD',
    locale: 'US',
    ...options,
  };
  return (amount || 0).toLocaleString(config.locale, {
    style: 'currency',
    currency: config.currency,
  });
};
