import clsx                                from 'clsx';
import PropTypes                           from 'prop-types';
import React, { Suspense, lazy, useState, useEffect } from 'react'
import logoPaths from './paths';

const PreferabliLogo = ({
  logoNumber=1,
  logoType='logomark',
  ...props
}) => {

  const [iconNotFound, setIsNotFound] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    if(logoNumber && logoNumber !== null){
      const logo = Object.values(logoPaths).find((optLogo) => {
        return (optLogo.id === Number(logoNumber));
      });
      setLogo(logo);
    }
  }, [logoNumber]);

  const { ...svgProps } = props;

  const DynamicLoader = (params) => {
    const LazyComponent = params.path;
    if (!LazyComponent) {
      console.error('No SVG was found that match your query.', props.icon);
      setIsNotFound(true)
      return null
    }
    return (
      <Suspense fallback={ <g /> }>
        <LazyComponent { ...svgProps }/>
      </Suspense>
    )
  }

  if (iconNotFound) {
    return null
  }

  return (
    <>
    {logo && (
    <svg
      viewBox={`0 0 ${logo.viewBox}`}
      className={ clsx(
        'lttt__preferabli',
        logo.type === 'wordmark' && 'lttt__preferabli--wordmark',
        logo.type === 'logomark' && 'lttt__preferabli--logomark',
        props.className,
        ) }
    >
      <DynamicLoader {...logo} />
    </svg>
    )}
    </>
  );

};

PreferabliLogo.propTypes = {
  logoNumber: PropTypes.number,
  logoType: PropTypes.string,
}

export default React.memo(PreferabliLogo)