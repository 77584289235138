const NUMBER_REGEX = /-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/;
const parseUnit = (str) => {
  const val = parseFloat(str);
  const numberStrings = str.match(NUMBER_REGEX) || [];
  const unit = numberStrings.length > 0
    ? str.substr(numberStrings[0].length).trim()
    : '';

  return [val, unit];
}

var PIXELS_PER_INCH = 96
var defaults = {
  'ch': 8,
  'ex': 7.15625,
  'em': 16,
  'rem': 16,
  'in': PIXELS_PER_INCH,
  'cm': PIXELS_PER_INCH / 2.54,
  'mm': PIXELS_PER_INCH / 25.4,
  'pt': PIXELS_PER_INCH / 72,
  'pc': PIXELS_PER_INCH / 6,
  'px': 1
}

const converToPixel = (str) => {
  if (!str && str !== 0) return null

  if (defaults[str]) return defaults[str]

  // detect number of units
  var parts = parseUnit(str);

  if (!isNaN(parts[0])) {
    if (parts[1]) {
      var px = converToPixel(parts[1])
      return typeof px === 'number' ? `${parts[0] * px}px` : null
    }
    else {
      return `${parts[0]}px`;
    }
  }

  return null
}

export default converToPixel;