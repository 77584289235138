export default class Context {
	constructor() {
		this.integration_id = null;
		this.product_id = null;
		this.lang = null;
		this.opts = null;
	}

	getProductId = () => this.product_id;
	getIntegrationId = () => this.integration_id;
	getLanguage = () => this.lang;
	getOptions = () => this.opts;

	setIntegrationId = (integration_id) => {
		this.integration_id = integration_id;
	}

	setProductId = (product_id) => {
		this.product_id = product_id;
	}

	setLanguage = (lang) => {
		this.lang = lang;
	}

	setOptions = (opts) => {
		this.opts = opts;
	}
}

