import Api from './Api';

export default class Integrations extends Api {

  constructor(){
    super();
    this._opts = null;
    this._lang = null;
  }

  setup = (params) => {
    this.client.defaults.baseURL = `${this.client.defaults.baseURL}/${params.integration_id}/`;
    this.client.defaults.headers.client_interface = `stp_${params.integration_id}`;
    this.client.defaults.headers.client_interface_version = `${__VERSION__}`;
    this._opts = params.opts;
    this._lang = params.lang;
  }

  getProductLttt = (params) => this.get(`lttt`, { ...params, return_details: true, }).then((res) => {
    if(res.merchant_vintage_details){
      res.products = res.merchant_vintage_details.map((_product) => ({
        ..._product,
        primary_image: {
          path: _product.image_url,
        },
        name: _product.product_name,
        id: _product.merchant_vintage_id,
        grape: null,
        region: null,
        category: null,
      }))
    }
    return res;
  });

}