import React, { useEffect, useState } from 'react';
import EventEmitter from 'UTILS/emitter';

export const STPContext = React.createContext({});
STPContext.name = 'STPContext';

export const STPProvider = ({ children, parameters }) => {
  const getStyles = (params) => {
    return '';
  }

  const hasDecimalPlaces = () => {
    if (!showDecimalPlaces) return 0;
    if (showDecimalPlaces && numDecimalPlaces) return Number(numDecimalPlaces);
    return 2;
  };

  const context = {
    ...parameters,
    getStyles,
    hasDecimalPlaces,
  };

  return <STPContext.Provider value={ context }>{children}</STPContext.Provider>;
};