import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Separator = (props) => {
	let {
		position,
		show,
		...controlledProps
	} = props;

	const [separatorStyle, setSeparatorStyle] = useState({});

	useEffect(() => {
		if(controlledProps.style && Object.keys(controlledProps.style).length){
			if(show) show = show.toLowerCase().trim();

			if(['border', 'borderTop', 'borderBottom', 'spacingTop', 'spacingBottom', 'spacing'].some((styleKey) => Object.keys(controlledProps.style).includes(styleKey))){

				const styleObj = Object.keys(controlledProps.style).reduce((obj, styleKey) => {
					if(new RegExp('^border', 'i').test(styleKey)){
						if(show === 'both') {
							if(Object.keys(controlledProps.style).every((key) => ['borderTop', 'borderBottom'].includes(key)) && styleKey.toLowerCase().includes(position)){
								if(typeof controlledProps.style[styleKey] === 'string'){
									obj.borderTop = controlledProps.style[styleKey];
								}
								if(typeof controlledProps.style[styleKey] === 'object'){
									if(controlledProps.style[styleKey]?.inline){
										Object.entries(controlledProps.style[styleKey]?.inline).map((entry) => {
											const [idx, inlineStyle] = entry;
											Object.assign(obj, inlineStyle);
										});
									}
									if(controlledProps.style[styleKey]?.style){
										obj.borderTop = controlledProps.style[styleKey]?.style;
									}
								}
							} 
							if(!Object.keys(controlledProps.style).every(value => ['borderTop', 'borderBottom'].includes(value)) && Object.keys(controlledProps.style).includes('border')){
								obj.borderTop = controlledProps.style[styleKey];
							}
						}
						if(show === 'top' || show === 'bottom'){
							if(['borderTop', 'borderBottom', 'border'].includes(styleKey)){
								if(typeof controlledProps.style[styleKey] === 'string'){
									obj.borderTop = controlledProps.style[styleKey];
								}
								if(typeof controlledProps.style[styleKey] === 'object'){
									if(controlledProps.style[styleKey]?.inline){
										Object.entries(controlledProps.style[styleKey]?.inline).map((entry) => {
											const [idx, inlineStyle] = entry;
											Object.assign(obj, inlineStyle);
										});
									}
									if(controlledProps.style[styleKey]?.style){
										obj.borderTop = controlledProps.style[styleKey]?.style;
									}
								}
							}
						}
					}

					if(styleKey.startsWith('spacing')){
						switch (styleKey){
							case 'spacingTop':
								obj.marginTop = controlledProps.style[styleKey];
							break;
							case 'spacingBottom':
								obj.marginBottom = controlledProps.style[styleKey];
							break;
							default: 
							obj.marginTop = controlledProps.style[styleKey];
							obj.marginBottom = controlledProps.style[styleKey];
						}
					}

					return obj;

				}, {});

				setSeparatorStyle(styleObj);
			}
		}
	}, [controlledProps.style]);

	const separatorClass = (controlledProps.class && typeof controlledProps.class === 'object' && controlledProps.class[position]) ? controlledProps.class[position] : controlledProps.class;


	return (
		<div className={clsx('lttt__separator', separatorClass)} style={separatorStyle} />
	);
};

export default React.memo(Separator);