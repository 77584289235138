import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

import DocEvents from 'UTILS/events';
import Context from 'UTILS/Context';
import Separator from 'COMPONENTS/Separator';
import LoadingDots from 'GLOBAL/LoadingDots';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';
import HelperTooltip from 'GLOBAL/HelperTooltip';
import withContext from 'HOC/withContext';

import Emitter from 'UTILS/emitter';
import { STPContext, STPProvider } from 'COMPONENTS/AppContext';

import { genUTM } from 'HELPERS/url';
import { omit, pick } from 'HELPERS/object';
import { localePrice } from 'HELPERS/price';

const STPDisplay = (props) => {

	const _c = useContext(STPContext);

	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setError] = useState(false);
	const [products, setProducts] = useState(null);

	const enableTopSeparator = props.display && props.display.separator && props.display.separator.show && ['top', 'both'].includes(props.display.separator.show);
	const enableBottomSeparator = props.display && props.display.separator && props.display.separator.show && ['bottom', 'both'].includes(props.display.separator.show);

	const [waitForCallback, setWaitForCallback] = useState(typeof props.renderProductCards === 'function');
	const [cards, setCards] = useState([]);

	const CustomResultCard = ({ html, ...propParams }) => {
	const options = {
		replace: ({attribs, children, name, ...domNodeProps}) => {

			if(!domNodeProps.parent && children && children.length){
				const Tag = name;
				const props = attributesToProps(attribs);
				return (<Tag {...props} onClick={ propParams.onClick }>{domToReact(children, options)}</Tag>);
			}
			if (attribs && name === 'input') {
				const props = attributesToProps(attribs);
				return (<input { ...props } onChange={ () => {} } />);
			}
			if (attribs && name === 'select') {
				const props = attributesToProps(attribs);
				return (<select { ...props } onChange={ () => {} } />);
			}
		},
	};
	return parse(html, { trim: true, ...options });
};

	useEffect(() => {
		if(products){

			const _doProducts = async () => {
			
				const _hasValidUrl = async (url) => (await fetch(url, {method: 'HEAD'})).ok;

				const allLookups = await Object.entries(products).reduce(async (acc, result) => {
					const joiner = await acc;
					const [objIdx, lookup ] = result;
					const _valid =  (__ENV__ === 'production') ? await _hasValidUrl(lookup.landing_url) : Promise.resolve();
		            if (lookup.landing_url && typeof lookup.landing_url === 'string' && lookup.landing_url.length && _valid) joiner.push(lookup);
					return joiner;
				}, Promise.resolve([]));

				props.renderProductCards(allLookups)
					.then((returnCards) => {
						const _cleanHtmlCards = returnCards.filter((_cardHtml) => (String(_cardHtml).length > 0));

						setCards([
							...new Set([
								..._cleanHtmlCards.map((cardHtml, idx) => {
									if (typeof cardHtml === 'string') {
										return { html: cardHtml, key: allLookups[idx].value, product: allLookups[idx] };
									}
									if (cardHtml.constructor === Promise) {
										/// do nothing
									}
								}),
							]),
						]);
						setWaitForCallback(false);
						if(typeof props.onComplete === 'function') props.onComplete();
						if(isLoading) setIsLoading(false);
					})
					.catch((error) => {
						console.log(error)
					});
			};


		if(Object.entries(products).length){
			if (typeof props.renderProductCards === 'function') _doProducts();
		}

		}
	}, [products]);

	useEffect(() => {
		if (cards.length && !waitForCallback) {
			if (typeof props.onRenderComplete === 'function') props.onRenderComplete();
		}
	}, [cards, waitForCallback]);


	useEffect(() => {
		const handleProductUpdate = (event) => {
			setProducts(event.detail.products.merchant_vintage_details);
			setIsLoading(false);
		};

		document.addEventListener(
			DocEvents.loadError,
			(event) => {
				setError(true);
				setIsLoading(false);
			}, false);

		document.addEventListener(
			DocEvents.updateProducts,
			(event) => {
				handleProductUpdate(event);
			},
			false
		);
	}, []);

	const hasDecimalPlaces = () => {
		if (!props.showDecimalPlaces) return 0;
		if (props.showDecimalPlaces && props.numDecimalPlaces) return Number(props.numDecimalPlaces);
		return 2;
	};

	const getConatinerClasses = (products) => {
		let arr =['lttt--grid'];
		if(props.display.layout === 'list') arr = [...arr, 'lttt--grid-gap-15'];								
		if(props.display.layout === 'column') arr = [...arr, 'lttt--grid-gap-25'];
		if(props.display.layout === 'row') arr = [...arr, 'lttt--grid-gap-15', `lttt--grid-columns-md-${products.length >= 4 ? '4' : products.length}`, 'lttt--grid-md-flow-col'];
		return arr;
	}

	const ProductCardsContainer = props.productCardsContainer && props.productCardsContainer.element || 'div';

	return (
		<>
			
		<div
			className={clsx(
				'lttt__container',
				(props.hideHeading || props.display.logo_placement === 'top-left') && `lttt__logo-spacer-top`, 
				(props.display.logo_placement.startsWith('bottom-')) && `lttt__logo-spacer-bottom`, 
				props.devMode && 'lttt--devmode',
				props.display.layout === 'list' && ['lttt--list-mode'],
				)}
		>
			{!isLoading && products && products.length > 0 && (
				<div className={clsx('lttt__header')}>
					{enableTopSeparator && <Separator position="top" {...props.display.separator} />}
					<div className={clsx('lttt__header--inner')}>
						{(products && Array.isArray(products) && !props.hideHeading) && (
							<h2>
								{(props.customText && props.customText[`${(products.length >= 2) ? 'multiple' : 'single'}`])}
								{(!props.customText && props.lang) && _c.lang.getSlugTranslation({ slug:`result.${(products.length >= 2) ? 'multiple' : 'single'}` }) }
							</h2>
						)}
						{props.helpTooltip && <HelperTooltip />}
					</div>
				</div>
			)}

			<div className={clsx('lttt__products--container')} data-stp-rendered={ Boolean(!isLoading && products && ((!props.renderProductCards) || (props.renderProductCards && !waitForCallback && cards.length))) }>
				{!isLoading && products && products.length > 0 && (
					<ProductCardsContainer
						className={ clsx(
							!props.productCardsContainer && getConatinerClasses(products),
							props.productCardsContainer && Object.keys(props.productCardsContainer).includes('class') && props.productCardsContainer.class,
						)}
						id={ props.productCardsContainer && Object.keys(props.productCardsContainer).includes('id') ? props.productCardsContainer.id : ''}
					>

					{typeof props.renderProductCards === 'function' && waitForCallback && (
						<LoadingDots className={ clsx('fade', waitForCallback && 'in') } style={{ paddingLeft:'16px' }} showText={ false } />
					)}

					{typeof props.renderProductCards === 'function' && cards.map((card, idx) => <CustomResultCard { ...card } onClick={() => {
						// e.preventDefault();
						let capture = pick(card.product, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);

						_c.analytics.track('lttt product clickthru', {
							...capture,
							product_id: card.product.value, 					
						});
					}} /> )}

						{!props.renderProductCards && products.map((product) => (
							<div className={clsx(
								'lttt__product',
								props.display.layout === 'list' && ['lttt__product--list'],
								)} key={ product.value }>
								<div className="lttt__product--inner">
									<a
										href={ genUTM(product.landing_url, product.product_name) }
										rel="noreferrer"
										target={ props.openNewTab ? '_blank' : '_self' }
										onClick={ () => {
											let capture = pick(product, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);
											_c.analytics.track('lttt product clickthru', {
												...capture,
												product_id: product.value,										
											});
										} }
									/>
									<div className="lttt__product--img">
										<img
											src={ product.image_url ? product.image_url : 'https://s3.amazonaws.com/winering-production/08cf8dd461082263c0abbc2d7e62b622' }
											alt={ product.product_name }
										/>
									</div>
									<div className="lttt__product--details">
										<span className="product-detail product-detail--name">
											{product.product_name}
										</span>
										<div className="lttt__product--details-footer">
											{(product.price) && (<span className="product-detail product-detail--price">{localePrice(product.price, product.price_currency)}</span>)}

											{props.showLink ? (
												<a
													className="product-detail product-detail--link"
													href={ genUTM(product.landing_url, product.product_name) }
													target={ props.openNewTab ? '_blank' : '_self' }
													rel="noreferrer"
													onClick={ () => {
														let capture = pick(product, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);
														_c.analytics.track('lttt product clickthru', {
															...capture,
															product_id: product.value,													
														});
													}}
												>
													{_c.lang.getSlugTranslation({ slug:`product.view_link` }) || 'View'}
												</a>
											) : null}
										</div>
									</div>
								</div>
							</div>
						))}
					</ProductCardsContainer>
				)}

				{isLoading && !products && (
					<div className={clsx('lttt__loader')}>
						<LoadingDots className={ clsx('fade', isLoading && 'in', !isLoading && 'd-none') } />
					</div>
				)}
			</div>

			{!isLoading && products && products.length > 0 && (
				<>
				<div className={clsx('lttt__footer')}>
					{enableBottomSeparator && <Separator position="bottom" {...props.display.separator} />}
				</div>

				
				<div className={clsx(
					'lttt__logo',
					`lttt__logo--${props.display.logo_placement}`,
					(props.display?.logoClass && props.display?.logoClass.length) && props.display.logoClass
					)} 
					style={props.display.logoInlineStyle}
				>
					{props.display?.logoBefore && parse(props.display.logoBefore)}
					{!props.display.use_preferabli_logo && (<PoweredByLogo logoNumber={props.display.logo_option} />)}
					{props.display.use_preferabli_logo && (<PreferabliLogo logoNumber={props.display.logo_option} />)}
					{props.display?.logoAfter && parse(props.display.logoAfter)}
				</div>

				</>
			)}
		</div>
			
		</>
	);
};

export default withContext(STPProvider, STPDisplay);
