import React, { useEffect } from 'react';

const useOutsideClick = (element = null, deps = [], handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!element || element.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, deps);
};

export default useOutsideClick;
